<template>
  <div class="ip-show">
    <div class="box">
      <div class="introduce">
        <span class="title">
          {{ languageSelect.my }}
        </span>
        <span class="ip-country">
          <i class="flag-icon ip-country-flag" :class="flagIpShow"></i>
          {{ countryName }}
          <div class="tooltip-container">
            <button class="btn" @click="getIpInfoCheck" v-if="ipInfoServerFinish" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
              <span>{{ languageSelect.check }}</span>
            </button>
            <span v-if="showTooltip" class="tooltip-text">点击此按钮以零缓存方式刷新此页面</span>
          </div>
        </span>
      </div>
      <div class="ip">
        <h1>{{ ipInfoServer.clientIp }}</h1>
      </div>
      <div class="safe-container">
        <a href="https://www.mbbrowser.com/" target="_blank" class="safe">
          {{ languageSelect.safe }}
          <div class="switch-box">
            <div class="switch-button"></div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue'
import { getIp } from "@/utils/api"
export default {
  name: "TheIpShow",
  setup () {
    const ipInfoServer = inject('ipInfoServer')
    const languageSelect = inject('languageSelect')
    const ipInfoServerFinish = inject('ipInfoServerFinish')
    const showTooltip = ref(false);

    const getIpInfoCheck = async () =>  {
      showTooltip.value = false;
      ipInfoServerFinish.value = false;

      const {
        city_name: cityName,
        clientip: clientIp,
        country_code: countryCode,
        country_name: countryName,
        domain,
        isp,
        latitude,
        longitude,
        region_name: regionName,
        time_zone: timeZone,
        time_zone_text: timeZoneNext,
        user_type: userType
      } = await getIp.info({check: 1}).then((res) => {
        return res.data.data
      })

      ipInfoServer.value = {
        cityName,
        clientIp,
        countryCode,
        countryName,
        domain,
        isp,
        latitude,
        longitude,
        regionName,
        timeZone,
        timeZoneNext,
        userType
      }
      ipInfoServerFinish.value = true;
    }
    return {
      ipInfoServer, languageSelect, getIpInfoCheck, ipInfoServerFinish, showTooltip
    }
  },
  computed: {
    flagIpShow () {
      return 'flag-icon-' + this.ipInfoServer.countryCode.toLowerCase()
    },
    countryName () {
      if (this.ipInfoServer.countryCode !== 'N/A') {
        if (this.ipInfoServer.countryCode.toLowerCase() in this.languageSelect.country) {
          return this.languageSelect.country[this.ipInfoServer.countryCode.toLowerCase()].name
        } else {
          return 'unknownCountry'
        }
      } else {
        return 'N/A'
      }
    }
  },
  methods: {
    refreshTime() {
    }
  }
}
</script>

<style scoped>
.ip-info{
  color: #FFFFFF;
}
.ip-show{
  padding: 60px 20px;
  display: flex;
  justify-content: center;
}
.ip{
  padding-bottom: 10px;
  border-bottom: 2px #4A5E70 solid;
  text-align: center;
  min-width: 330px;
}
.ip h1{
  font-size: 48px;
  color: #FFFFFF;
  font-weight: 600;
}

.introduce{
  display: flex;
  padding-bottom: 4px;
  font-weight: bold;
}

.title{
  font-size: 24px;
  color: #FFFFFF;
  flex: 1;
}

.ip-country{
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #FFFFFF;
}

.ip-country-flag{
  border-radius: 4px;
  overflow: hidden;
  display: block;
  padding-right: 6px;
  margin-top: 3px;
}

.safe-container{
  display: flex;
  align-items: center;
  justify-content: center;
}
.safe{
  display: flex;
  height: 30px;
  line-height: 30px;
  font-size: 24px;
  color: #FFFFFF;
  font-weight: bold;
  padding-top: 10px;
}

.switch-box{
  height: 30px;
  width: 60px;
  background: #E6E6E6;
  border-radius: 30px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.switch-button{
  height: 25px;
  width: 25px;
  border-radius: 25px;
  background: #FFFFFF;
  margin-left: 4px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn{
  margin-left: 10px;
  background-color: #42B983;
  border: none;
  color: white;
  padding: 3px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3ca978;
  }
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  font-weight: normal;
  font-size: 14px;
  visibility: visible;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;

  /* 设置箭头 */
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
}
@media only screen and ( max-width: 468px ){
  .title{
    font-size: 18px;
  }
  .ip h1{
    font-size: 36px;
  }
  .safe{
    font-size: 16px;
  }
}
</style>
