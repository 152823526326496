import { getIp } from "@/utils/api"
import { onMounted, ref } from 'vue'

export default function fetchIpInfoServer(){
	const ipInfoServerFinish = ref(false)
	const ipInfoServer = ref({
		cityName: 'N/A',
		clientIp: 'N/A',
		countryCode: 'N/A',
		countryName: 'N/A',
		domain: 'N/A',
		isp: 'N/A',
		regionName: 'N/A',
		timeZone: 'N/A',
		timeZoneNext: 'N/A',
		latitude: 'N/A',
		longitude: 'N/A',
		userType: 'Unknown'
	})
	const getIpInfo = async () => {
		const {
			city_name: cityName,
			clientip: clientIp,
			country_code: countryCode,
			country_name: countryName,
			domain,
			isp,
			latitude,
			longitude,
			region_name: regionName,
			time_zone: timeZone,
			time_zone_text: timeZoneNext,
			user_type: userType
		} = await getIp.info().then((res) => {
			console.log(res)
			return res.data.data
		})

		ipInfoServer.value = {
			cityName,
			clientIp,
			countryCode,
			countryName,
			domain,
			isp,
			latitude,
			longitude,
			regionName,
			timeZone,
			timeZoneNext,
			userType
		}
		ipInfoServerFinish.value = true
	}
	onMounted(()=>{
		getIpInfo().then()
	})
	return {
		ipInfoServerFinish, ipInfoServer
	}
}
