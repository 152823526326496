/**
 * axios封装
 * */

import axios from 'axios'
import Qs from 'qs'

/**
 * 读取当前网站的语言环境
 * */

/**
 * 请求失败后的处理
 * @param {number} status 请求失败的状态码
 * @param {string} other
 * */

const errorHandler = (status) => {
  // 判断状态码
  switch (status) {
    case 401:
      console.log('HTTP错误401.1')
      break
    case 403:
      console.log('登录过期,请重新登录')
      break
    case 404:
      console.log('请求的资源不存在')
      break
    default:
      console.log('请求错误或服务器未响应')
  }
}

/**
 * 请求成功后的处理
 * */


/**
 * 创建axios,并设置配置
 * */

const service = axios.create({
  timeout: 15000, // 请求超时时间
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    // 'Access-Control-Allow-Credentials': true
  }
});

// 设置post
service.defaults.transformRequest = [obj => Qs.stringify(obj)]
service.defaults.withCredentials = false

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    if (config.data === undefined) {
      config.data = {}
    }
    config.data.lang = 'en'
    config.data.inweb = 1
    return config
  },
)

/**
 * 响应处理
 * */
service.interceptors.response.use(
  // 判断请求状态
  (res) => {
    const status = res.status
    if (status === 200) {
      /**
       * 请求成功，判断服务端code返回状态,做响应处理
       * */
      switch (res.data.code) {
        case -101:
          console.log('后台关闭注册功能')
          break
        case -102:
          console.log('后台关闭登录功能')
          break
        case -103:
          console.log('token无效或过期')
          break
        default:
          break
      }return Promise.resolve(res)
    } else {
      return Promise.reject('请求错误或服务器未响应')
    }
  },
  err => {
    errorHandler(err.status, err.msg)
    return Promise.reject('请求错误或服务器未响应')
  }
)

export default service
