<template>
  <div class="amazon">
    <div class="amazon-link">
      <ul>
        <li>
          <a href="https://www.amazon.com/" target="_blank">
            <img src="../assets/img/a-usa.png" width="40" alt="美国亚马逊">
            <p>美国亚马逊</p>
          </a>
        </li>
        <li>
          <a href="https://www.amazon.co.uk/" target="_blank">
            <img src="../assets/img/a-en.png" width="40" alt="英国亚马逊">
            <p>英国亚马逊</p>
          </a>
        </li>
        <li>
          <a href="https://www.amazon.de/" target="_blank">
            <img src="../assets/img/a-ge.png" width="40" alt="德国亚马逊">
            <p>德国亚马逊</p>
          </a>
        </li>
        <li>
          <a href="https://www.amazon.co.jp/" target="_blank">
            <img src="../assets/img/a-jp.png" width="40" alt="日本亚马逊">
            <p>日本亚马逊</p>
          </a>
        </li>
        <li>
          <a href="https://www.amazon.fr/" target="_blank">
            <img src="../assets/img/a-fr.png" width="40" alt="法国亚马逊">
            <p>法国亚马逊</p>
          </a>
        </li>
        <li>
          <a href="https://www.amazon.it/" target="_blank">
            <img src="../assets/img/a-italy.png" width="40" alt="意大利亚马逊">
            <p>意大利亚马逊</p>
          </a>
        </li>
        <li>
          <a href="https://www.amazon.ca/" target="_blank">
            <img src="../assets/img/a-canada.png" width="40" alt="加拿大亚马逊">
            <p>加拿大亚马逊</p>
          </a>
        </li>
        <li>
          <a href="https://www.amazon.es/" target="_blank">
            <img src="../assets/img/a-spain.png" width="40" alt="西班牙亚马逊">
            <p>西班牙亚马逊</p>
          </a>
        </li>
        <li>
          <a href="https://www.amazon.com.au/" target="_blank">
            <img src="../assets/img/a-au.png" width="40" alt="澳洲亚马逊">
            <p>澳洲亚马逊</p>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Amazon"
}
</script>

<style>
.amazon{
  max-width: 1200px;
  margin: 0 auto;
  padding:0 20px;
}
.amazon .title h2{
  font-size: 14px;
  color: #71818F;
  font-weight: 500;
}
.amazon-link ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.amazon-link ul li{
  padding: 30px;
  text-align: center;
}
.amazon-link ul li p{
  padding-top: 10px;
  font-size: 14px;
}
.amazon-link ul li a{
  color: #262626;
  height: 100px;
  width: 60px;
  display: block;
}
@media only screen and ( max-width: 468px ){
  .amazon-link ul li{
    padding: 20px;
  }
}
</style>
