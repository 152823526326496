import { ref, onMounted, onBeforeUnmount } from 'vue'

export default function getTime() {

  // 当前时间
  const nowTime = ref('')
  const nowTimezoneClient = ref('')
  const worldClock = ref({
    est: 'N/A',
    cst: 'N/A',
    london: 'N/A',
    euro: 'N/A',
  })

  let timeInterval
  function dateTime() {
    const local = new Date()
    nowTime.value = local.toLocaleString('zh-CN')
    const est = local.toLocaleString('zh-CN', {timeZone: 'America/New_York'})
    const cst = local.toLocaleString('zh-CN', {timeZone: 'America/Belize'})
    const london = local.toLocaleString('zh-CN', {timeZone: 'Europe/London'})
    const euro = local.toLocaleString('zh-CN', {timeZone: 'Europe/Berlin'})
    worldClock.value = {
      est, cst, london, euro
    }
  }

  console.log(new Intl.DateTimeFormat('en', {timeZoneName: 'short'}).resolvedOptions())

  function pad(value) {
    return value < 10 ? '0' + value : value
  }

  const sign = (new Date().getTimezoneOffset() > 0) ? '-' : '+'
  const offset = Math.abs(new Date().getTimezoneOffset())
  const hours = pad(Math.floor(offset / 60))
  const minutes = pad(offset % 60)

  nowTimezoneClient.value = sign + hours + ':' + minutes



  onMounted(()=>{
    timeInterval = setInterval(dateTime, 1000)
  })

  onBeforeUnmount(()=>{
    clearInterval(timeInterval)
  })

  return{
    nowTime,
    nowTimezoneClient,
    worldClock
  }
}
