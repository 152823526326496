<template>
  <div class="amazon">
    <div class="amazon-link">
      <ul>
        <li>
          <a href="https://www.aliexpress.com/" target="_blank">
            <img src="../assets/img/aliexpress.png" width="48" alt="alipress">
            <p>Aliexpress</p>
          </a>
        </li>
        <li>
          <a href="https://www.ebay.com/" target="_blank">
            <img src="../assets/img/ebay.png" width="48" alt="ebay">
            <p>ebay</p>
          </a>
        </li>
        <li>
          <a href="https://www.walmart.com/" target="_blank">
            <img src="../assets/img/walmart.png" width="48" alt="walmart">
            <p>Walmart</p>
          </a>
        </li>
        <li>
          <a href="https://www.wish.com/" target="_blank">
            <img src="../assets/img/wish.png" width="48" alt="wish">
            <p>Wish</p>
          </a>
        </li>
        <li>
          <a href="https://www.shopee.com/" target="_blank">
            <img src="../assets/img/shopee.png" width="48" alt="shopee">
            <p>Shopee</p>
          </a>
        </li>
        <li>
          <a href="https://www.lazada.com/" target="_blank">
            <img src="../assets/img/lazada.png" width="48" alt="lazada">
            <p>Lazada</p>
          </a>
        </li>
      </ul>
    </div>
    <div class="amazon-link">
      <ul>
        <li>
          <a href="https://www.paypal.com/" target="_blank">
            <img src="../assets/img/paypal.png" width="48" alt="paypal">
            <p>PayPal</p>
          </a>
        </li>
        <li>
          <a href="https://www.huobi.com/" target="_blank">
            <img src="../assets/img/huobi.png" width="48" alt="huobi">
            <p>火币网</p>
          </a>
        </li>
        <li>
          <a href="https://www.bitpay.com/" target="_blank">
            <img src="../assets/img/bitpay.png" width="48" alt="bitpay">
            <p>Bitpay</p>
          </a>
        </li>
        <li>
          <a href="https://www.payoneer.com/" target="_blank">
            <img src="../assets/img/payoneer.png" width="48" alt="payoneer">
            <p>Payoneer</p>
          </a>
        </li>
      </ul>
    </div>
    <div class="amazon-link">
      <ul>
        <li>
          <a href="https://translate.google.cn/" target="_blank">
            <img src="../assets/img/translate.png" width="48" alt="translate">
            <p>Google翻译</p>
          </a>
        </li>
        <li>
          <a href="https://www.foxmail.com/" target="_blank">
            <img src="../assets/img/foxmail.png" width="48" alt="foxmail">
            <p>FoxMail</p>
          </a>
        </li>
        <li>
          <a href="https://voice.google.com/" target="_blank">
            <img src="../assets/img/voice.png" width="48" alt="googleVoice">
            <p>GoogleVoice</p>
          </a>
        </li>
        <li>
          <a href="https://www.textnow.com/" target="_blank">
            <img src="../assets/img/textnow.png" width="48" alt="textnow">
            <p>TextNow</p>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheOther"
}
</script>

<style scoped>

</style>
