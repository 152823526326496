<template>
  <div class="search" ref="search">
    <label class="ip-search">
      <input
          class="ip-search-input"
          placeholder="请输入要查找的IP"
          v-model="ipInput"
          @focus="ipResultShow = true"
          v-on:keyup.enter="submitIpSearch(ipInput)"
      />
    </label>
    <i class="iconfont search-button" @click="submitIpSearch(ipInput)">&#xe67d;</i>
    <div class="ip-search-result"
         v-if="ipResultShow"
    >
      <div class="ip-search-result-init">
        <!--IP-->
        <h2 class="ip-search-result-title" @click="submitIpSearch(ipInput)">{{ ipInput }}</h2>
        <!--提示-->
        <span class="ip-search-result-tips">{{ ipResultTips }}</span>
        <!--国旗-->
        <div class="flag-icon ip-country-flag" :class="countryFlag" v-if="ipResultDetailShow"></div>
        <div class="ip-search-result-detail" v-if="ipResultDetailShow">
          <h4>{{ ipResultCountry }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getIp} from "@/utils/api";
import { onClickOutside } from '@vueuse/core'
import {ref} from "vue";

export default {
  name: "IpCountrySearch",
  setup() {
    /**
     * 点击在比特币换算以外，关闭弹出窗口
     * */
    const ipResultShow = ref(false)
    const search = ref(null) // 不会触发关闭的区域
    onClickOutside(search, () => {
      ipResultShow.value = false
    })
    return {
      search, ipResultShow
    }
  },
  data () {
    return {
      ipInput: '',
      ipResultTips: '请输入正确IP...',
      ipResultCountry: 'N/A',
      ipResultDetailShow: false,
      countryFlag: ''
    }
  },
  methods: {
    clickAway() {
      this.ipResultShow = false
    },
    isIP(strIP) {
      let re = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/g //匹配IP地址的正则表达式
      if (re.test(strIP)) {
        if ( RegExp.$1 < 256 && RegExp.$2 < 256 && RegExp.$3 < 256 && RegExp.$4 < 256) {
          return true;
        }
      }
      return false
    },
    submitIpSearch (ip) {
      if (this.isIP(ip)) {
        this.ipResultShow = true
        this.ipResultTips = '查询中...'
        getIp.country(ip).then((res) => {
          this.ipResultTips = res.data.data.countryCode
          this.countryFlag = 'flag-icon-' + res.data.data.countryCode.toLowerCase()
          this.ipResultDetailShow = true
          this.ipResultCountry = res.data.data.countryName
          console.log(res)
        }).catch((err) => {
          this.ipResultTips = '查询失败，请重试或联系管理员'
          console.log(err)
        })
      } else {
        return false
      }
    }
  },
  watch: {
    ipInput: function (newVal) {
      if (this.isIP(newVal)){
        this.ipResultTips = '按下回车查询'
      } else {
        this.ipResultDetailShow = false
        this.ipResultTips = '请输入正确的IP'
      }
    }
  }
}
</script>

<style scoped>
.search{
  position: relative;
  display: flex;
}
.ip-search{
  width: 100%;
  display: block;
}
.ip-search-input{
  height: 30px;
  width: 100%;
  background: rgba(255, 255, 255, 0.01);
  border: 1px #728290 solid;
  font-size: 14px;
  font-weight: bold;
  color: #9AA5B0;
  line-height: 30px;
  display: block;
  text-indent: 10px;
}
input.ip-search-input:focus{
  outline: none;
  background: #FFFFFF;
  color: #273848;
}
input.ip-search-input::placeholder{
  color: #9AA5B0;
  font-weight: normal;
  font-size: 12px;
}
.search-button{
  position: absolute;
  right: 10px;
  top: 7px;
  color: #9AA5B0;
}
.ip-search-result{
  background: #FFFFFF;
  position: absolute;
  width: 100%;
  left: 0;
  top: 40px;
  border-radius: 2px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 9;
}
.ip-search-result-init{
  padding: 10px;
  position: relative;
}
.ip-search-result-title{
  color: #42B983;
}
.ip-search-result-tips{
  color: #999;
}
.ip-country-flag{
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 22.5px;
  border-radius: 2px;
  overflow: hidden;
}
</style>
