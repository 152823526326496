import axios from 'axios'
import {getDns} from '@/utils/api'

import {onMounted, ref} from 'vue'

export default function fetchDns() {
  const dns = ref([
    {
      ip: 'N/A',
      geo: [{
        cc: 'N/A',
        country: 'N/A',
        isp: 'N/A',
        region: 'N/A',
        latitude: 'N/A',
        longitude: 'N/A'
      }]
    }
  ])
  const dnsFinish = ref(0)
  const errorCount = ref(0)

  const getDnsData = new Promise((resolve, reject) => {
    const $chars = 'abcdefghijklmnopqrstuvwxyz1234567890'
    const maxPos = $chars.length
    let id = ''
    for (let i = 0; i < 12; i++) {
      id += $chars.charAt(Math.floor(Math.random() * maxPos))
    }
    for (let index = 0; index < 5; index++) {
      //const img = new Image()
      //img.src = 'https://' + id + index + '.dnsleaktest.ip8.com'
      //img.style.display = 'none'
      //img.onerror = errorHandle()
      const checkerURL = 'https://' + id + index + '.strongleakstest.com/ajax.js'
      setTimeout(() => {
        axios({
          method: 'post',
          url: checkerURL,
          timeout: 1000,
          dataType: 'script'
        }).catch(() => {
          errorHandle()
        })
      }, 1)
    }
    function errorHandle() {
      errorCount.value ++
      console.log(errorCount.value)
      if (errorCount.value == 5){
        // 请求dns信息
        getDns.info(id).then(res => {
          if (res.data.code === 0) {
            const resData = res.data.data
            const data = [{
              ip: resData.ipAddress,
              geo: [{
                cc: resData.countryCode,
                country: resData.countryName,
                isp: resData.isp,
                region: resData.regionName,
                latitude: 'N/A',
                longitude: 'N/A',
              }]
            }]
            resolve(data)
          } else {
            reject(-1)
          }
        })
      }
    }


    setTimeout(()=>{
      reject(-2)
    }, 15000)
  })

  onMounted(() => {
    getDnsData.then((res)=> {
      if (res[0] !== undefined) {
        dnsFinish.value = 1
        dns.value = res
      } else {
        dnsFinish.value = -1
      }
    }).catch((err) => {
      console.log(err)
      dnsFinish.value = err
    })
  })

  return {
    dns, dnsFinish
  }
}
