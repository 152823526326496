<template>
  <div class="amazon">
    <div class="amazon-link">
      <ul>
        <li>
          <a href="https://mail.google.com/" target="_blank">
            <img src="../assets/img/gmail.png" width="48" alt="gmail">
            <p>gmail</p>
          </a>
        </li>
        <li>
          <a href="https://outlook.live.com/owa/" target="_blank">
            <img src="../assets/img/outlook.png" width="48" alt="outlook">
            <p>outlook</p>
          </a>
        </li>
        <li>
          <a href="https://outlook.live.com/owa/" target="_blank">
            <img src="../assets/img/hotmail.png" width="48" alt="hotmail">
            <p>hotmail</p>
          </a>
        </li>
        <li>
          <a href="https://mail.ru/" target="_blank">
            <img src="../assets/img/mail.ru.png" width="48" alt="mail.ru">
            <p>Mail.ru</p>
          </a>
        </li>
        <li>
          <a href="https://login.yahoo.com/" target="_blank">
            <img src="../assets/img/Yahoo.png" width="48" alt="yahoo">
            <p>Yahoo</p>
          </a>
        </li>
        <li>
          <a href="https://www.mail.com/" target="_blank">
            <img src="../assets/img/mail.png" width="48" alt="mail">
            <p>Mail</p>
          </a>
        </li>
        <li>
          <a href="https://email.163.com/" target="_blank">
            <img src="../assets/img/163.png" width="48" alt="163">
            <p>163邮箱</p>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheEmail"
}
</script>

<style scoped>

</style>

