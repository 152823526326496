<template>
  <the-status-bar></the-status-bar>
  <section class="bg-container">
    <the-header
      v-on:language-change="changeLanguage"
    ></the-header>
    <the-ip-show></the-ip-show>
    <the-ip-info></the-ip-info>
    <the-progress></the-progress>
  </section>
  <the-finger-prints></the-finger-prints>
  <section class="nav-container">
    <the-amazon></the-amazon>
    <the-email></the-email>
    <the-other></the-other>
    <the-footer></the-footer>
  </section>
  <the-time></the-time>
  <ad-show></ad-show>
</template>

<script>
// composables
import fetchExchanges from "./composables/fetchExchanges"
import fetchIpInfoServer from "./composables/fetchIpInfoServer"
import fetchDns from "./composables/fetchDns"
import fetchIpWebrtc from "./composables/fetchIpWebrtc"

// language
import language from './utils/language.json'

// components
import TheStatusBar from "./components/TheStatusBar.vue"
import TheHeader from "./components/TheHeader.vue"
import TheIpShow from "./components/TheIpShow.vue"
import TheTime from "./components/TheTime.vue"
import TheIpInfo from "./components/TheIpInfo.vue"
import TheProgress from "./components/TheProgress.vue"
import TheFingerPrints from "./components/TheFingerPrints.vue"
import TheAmazon from "./components/TheAmazon.vue"
import TheEmail from "./components/TheEmail.vue"
import TheOther from "./components/TheOther.vue"
import TheFooter from "./components/TheFooter.vue"
import AdShow from "./components/AdShow.vue"


// setup
import { provide, watch, computed } from 'vue'
import getTime from "./composables/getTime"
import getUserAgentInfo from "./composables/getUserAgentInfo"


export default {
  name: 'App',
  components: {
    TheFingerPrints,
    TheProgress,
    TheIpInfo,
    TheIpShow,
    TheHeader,
    TheStatusBar,
    TheTime,
    TheAmazon,
    TheEmail,
    TheOther,
    TheFooter,
    AdShow
  },
  setup () {
    // 语言切换
    // 汇率
    const { money, btc, moneyFinish, btcFinish } = fetchExchanges()
    provide('money', money)
    provide('moneyFinish', moneyFinish)
    provide('btc', btc)
    provide('btcFinish', btcFinish)


    // ipInfo
    const { ipInfoServer, ipInfoServerFinish } = fetchIpInfoServer()
    provide('ipInfoServer', ipInfoServer)
    provide('ipInfoServerFinish', ipInfoServerFinish)
    watch(ipInfoServerFinish, (newVal) => {
      if (newVal) {
        console.log('*********IP信息请求完成*********')
      } else {
        console.log(ipInfoServer)
      }
    })


    // webRtc
    const { ipWebrtc, ipWebrtcFinish } = fetchIpWebrtc()
    watch(ipWebrtcFinish, (newVal)=> {
      if (newVal === 1) {
        console.log('*********Webrtc请求完成*********')
        console.log(ipWebrtc.value)
      } else if (newVal === -1){
        console.log('*********Webrtc请求超时*********')
      } else {
        console.log(ipWebrtc.value)
      }
    })
    provide('ipWebrtc', ipWebrtc)
    provide('ipWebrtcFinish', ipWebrtcFinish)

    // DNS
    const { dns, dnsFinish } = fetchDns()
    provide('dnsFinish', dnsFinish)
    provide('dns', dns)

    // 时间
    const { nowTime, nowTimezoneClient, worldClock } = getTime()
    provide('nowTimezoneClient', nowTimezoneClient)
    provide('nowTime', nowTime)
    provide('worldClock', worldClock)

    // userAgent
    const { userAgentInfo } = getUserAgentInfo()
    provide('userAgentInfo', userAgentInfo)
  },
  data () {
    return {
      languageSelectCode: 'cn'
    }
  },
  provide () {
    return {
      languageSelect: computed(() => language[this.languageSelectCode])
    }
  },
  methods: {
    changeLanguage(cc) {
      localStorage.setItem('language', cc)
      this.languageSelectCode = cc
    }
  }
}
</script>
<style>
.bg-container{
  background-image: url("./assets/web-bg-tiny.png");
  background-color: #3A5062;
  background-size: cover;
}
.nav-container{
  margin-top: 20px;
  padding-top: 30px;
  background: #F8F9FA;
}
#app{
  overflow: hidden;
}
@media only screen and ( max-width: 1200px ) {
  .bg-container{
    padding: 0 20px;
  }
}
</style>
