<template>
  <div class="time-fixed">
    <div class="time">
      <ul>
        <li>
          <span>现在时间</span>
          <h2>{{ nowTime }}</h2>
        </li>
        <li>
          <span>美国东部时间</span>
          <h2>{{ worldClock.est }}</h2>
        </li>
        <li>
          <span>伦敦时间</span>
          <h2>{{ worldClock.london }}</h2>
        </li>
        <li>
          <span>美国西部时间</span>
          <h2>{{ worldClock.cst }}</h2>
        </li>
        <li>
          <span>欧洲时间</span>
          <h2>{{ worldClock.euro }}</h2>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { provide } from 'vue'
import getTime from "../composables/getTime";
export default {
  name: "TheTime",
  setup(){
    const { nowTime, nowTimezoneClient, worldClock } = getTime()
    provide('nowTimezoneClient', nowTimezoneClient)
    return {
      nowTime, nowTimezoneClient, worldClock
    }
  }
}
</script>

<style scoped>
.time-fixed{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background: #eff1f5;
}
.time{
  padding: 10px 0;
  color: #404040;
  width: 100%;
  max-width: 1200px;
}
.time ul{
  display: flex;
}
.time ul li{
  margin-right: 10px;
  width: 20%;
}
.time ul li span{
  color: rgba(0, 0, 0, 0.5);
  text-align: left;
}
.time ul li h2{
  font-size: 18px;
}
.hash{
  font-size: 20px;
  margin: 0;
}
@media only screen and (max-width: 1024px) {
  .time-fixed{
    display: none;
  }
}
</style>
