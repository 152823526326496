<template>
  <div class="ip-info">
    <div class="ip-info-init">
      <ul>
        <li>
          <section class="info-block info-block-left">
            <div class="title">{{ languageSelect.ipInfo.host }}</div>
            <div class="content">
              <i class="icon-ip">
                <img src="../assets/img/icon-admin.png" height="20" alt="isp">
              </i>
              {{ ipInfoServer.domain }}
            </div>
          </section>
          <section class="info-block info-block-right">
            <div class="title">{{ languageSelect.ipInfo.dns }}</div>
            <div class="content">
              <i class="icon-ip">
                <img src="../assets/img/icon-dns.png" height="20" alt="isp">
              </i>
              <div class="dns">
                <b @click="dnsShow = !dnsShow">
                  {{ dns[0].ip }}
                  <span>{{ dns.length }}</span>
                </b>
                <i class="flag-icon" :class="dnsCountryFlag"></i>
                {{ dnsCountryName }}
                <div class="dns-list" v-if="dnsShow" ref="dnsBox">
                  <ul>
                    <li v-for="(info, index) in dns" :key="index">
                      <h4><span>{{ index + 1 }}.</span>{{ info.ip }}</h4>
                      <div class="dns-list-content">
                        <i class="flag-icon" :class="'flag-icon-' + info.geo[0].cc.toLowerCase()"></i>
                        {{ info.geo[0].country }}
                        {{ info.geo[0].isp }}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </li>
        <li>
          <section class="info-block info-block-left">
            <div class="title">{{ languageSelect.ipInfo.os }}</div>
            <div class="content">
              <i class="icon-ip">
                <img src="../assets/img/icon-system.png" height="20" alt="isp">
              </i>
              {{ userAgentInfo.os.name }} {{ userAgentInfo.os.versionName }}
            </div>
          </section>
          <section class="info-block">
            <div class="title">{{ languageSelect.ipInfo.webrtc }}</div>
            <div class="content">
              <i class="icon-ip">
                <img src="../assets/img/icon-ip.png" height="20" alt="isp">
              </i>
              <div class="dns">
                <span class="mark-container" v-if="!(ipWebrtc.public === 'N/A')">
                  <span class="webrtc" v-if="!(ipWebrtc.client === 'N/A')">
                    {{ ipWebrtc.client }}
                    <span class="mark green">L</span>
                  </span>
                  <span class="webrtc" v-if="!(ipWebrtc.public === 'N/A')">
                    {{ ipWebrtc.public }}
                    <span class="mark red">P</span>
                  </span>
                </span>

                <span class="mark-container" v-if="ipWebrtcFinish === 1">
                  <span class="mark-container" v-if="ipWebrtc.public === 'N/A'">
                    查询超时或已关闭
                  </span>
                </span>
                <span class="mark-container" v-if="ipWebrtcFinish === -1">
                  查询超时或已关闭
                </span>
                <!--                |-->
                <!--                <span class="mark-container">{{ ipInfoServer.clientIp }}<span class="mark"></span></span>-->
              </div>
            </div>
          </section>
        </li>
        <li>
          <section class="info-block info-block-left">
            <div class="title">{{ languageSelect.ipInfo.browser }}</div>
            <div class="content">
              <i class="icon-ip">
                <img src="../assets/img/icon-browser.png" height="20" alt="isp">
              </i>
              {{ userAgentInfo.browser.name }} {{ userAgentInfo.browser.version }}
            </div>
          </section>
          <section class="info-block">
            <div class="title">{{ languageSelect.ipInfo.location }}</div>
            <div class="content">
              <i class="icon-ip">
                <img src="../assets/img/icon-address.png" height="20" alt="isp">
              </i>
              <div class="dns">
                {{ ipInfoServer.regionName }} | {{ ipInfoServer.latitude }}{{ ipInfoServer.longitude }}
              </div>
            </div>
          </section>
        </li>
        <li>

          <section class="info-block info-block-left">
            <div class="title">{{ languageSelect.ipInfo.timezone }}</div>
            <div class="content">
              <i class="icon-ip">
                <img src="../assets/img/icon-isp.png" height="20" alt="isp">
              </i>
              <span class="over-hidden">{{ ipInfoServer.timeZoneNext }} | {{ ipInfoServer.timeZone}}</span>
            </div>
          </section>
          <section class="info-block">
            <div class="title">{{ languageSelect.ipInfo.language }}</div>
            <div class="content">
              <i class="icon-ip">
                <img src="../assets/img/icon-language.png" height="20" alt="isp">
              </i>
              {{ userAgentInfo.language }}
            </div>
          </section>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {inject, onMounted, ref, watch} from "vue";
import { onClickOutside } from '@vueuse/core'

export default {
  name: "TheIpInfo",
  setup() {
    const dnsShow = ref(false)
    /**
     * 点击在比特币换算以外，关闭弹出窗口
     * */
    const dnsBox = ref(null) // 不会触发关闭的区域
    onClickOutside(dnsBox, () => {
      dnsShow.value = false
    })
    // 获取参数
    const mbQuery = ref('')
    const sessionID = ref('')
    const token = ref('')
    onMounted(()=>{
      const query = window.location.search.substring(1)
      const vars = query.split('&')
      for (let i=0; i<vars.length; i++) {
        let pair = vars[i].split('=')
        if (pair[0] === 'wd') {
          mbQuery.value = pair[1]
        } else if (pair[0] === 'id') {
          sessionID.value = pair[1]
        } else if (pair[0] === 'tk') {
          token.value = pair[1]
        }
      }
    })
    const ipInfoServer = inject('ipInfoServer')
    const ipWebrtc = inject('ipWebrtc')
    const ipWebrtcFinish = inject('ipWebrtcFinish')
    const userAgentInfo = inject('userAgentInfo')
    const dns = inject('dns')
    const dnsFinish = inject('dnsFinish')
    const languageSelect = inject('languageSelect')
    watch(dnsFinish, (newVal) => {
      if (newVal === 1) {
        console.log('********dns请求成功********')
      } else if (newVal === -2) {
        console.log('********dns请求超时********')
      } else {
        console.log('********dns请求失败********')
      }
    })
    return {
      ipInfoServer, ipWebrtc, ipWebrtcFinish, userAgentInfo, dns, languageSelect, mbQuery, dnsBox, dnsShow
    }
  },
  methods: {},
  computed: {
    dnsCountryName() {
        if (this.dns[0].geo[0].cc !== 'N/A') {
          return this.languageSelect.country[this.dns[0].geo[0].cc.toLowerCase()].name
        } else {
          return 'N/A'
        }
    },
    dnsCountryFlag() {
      return 'flag-icon-' + this.dns[0].geo[0].cc.toLowerCase()
    }
  }
}
</script>

<style scoped>
.ip-info {
  display: flex;
  justify-content: center;
}

.ip-info-init {
  max-width: 1200px;
  width: 100%;
}

.ip-info ul li {
  display: flex;
  color: #FFFFFF;
}

.info-block {
  display: flex;
  width: 50%;
  padding: 18px 0;
  border-bottom: 1px #495D6F solid;
}

.title {
  font-size: 20px;
  color: #71818F;
  width: 150px;
  flex-shrink: 0;
}

.content {
  font-size: 20px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  max-width: 360px;
  flex-shrink: 1;
  overflow: hidden;
}

.webrtc {
  position: relative;
  margin-right: 20px;
}

.info-block-left {
  margin-right: 20px;
}

.icon-ip {
  display: block;
  text-align: center;
  width: 20px;
  margin-right: 10px;
}

.icon-ip img {
  max-width: 20px;
}

.dns {
  position: relative;
}

.dns b {
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-decoration-color: #9aa5b0;
  text-decoration-width: 1px;
  position: relative;
  padding-right: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.dns b span {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #FF9900;
  font-weight: 600;
}

.dns-list {
  position: absolute;
  left: 0;
  bottom: 40px;
  background: #FFFFFF;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px #e6e6e6 solid;
}

.dns-list ul li {
  color: #71818F;
  display: block;
  font-size: 14px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px #e6e6e6 dashed;
}

.dns-list ul li:last-child {
  border: none;
}

.dns-list ul li h4 {
  font-size: 18px;
  color: #2c3e50;
}

.dns-list ul li h4 span {
  padding: 1px 5px 1px 0;
  color: #42B983;
}

.dns-list-content {
  padding-left: 18px;
}

.over-hidden {
  display: block;
  overflow: hidden;
  max-width: 100%;
}

.mark-container {
  position: relative;
  margin-right: 40px;
}

.mark {
  position: absolute;
  top: 0;
  right: -12px;
  font-size: 14px;
  color: #42B983;
  font-weight: 600;
}

.green {
  color: #42B983;
}

.red {
  color: #FF9900;
}

@media only screen and ( max-width: 1024px ) {
  .ip-info {

  }

  .ip-info ul li {
    flex-wrap: wrap;
  }

  .info-block {
    width: 100%;
  }

  .info-block-left {
    margin: 0;
  }
}

@media only screen and ( max-width: 468px ) {
  .title {
    font-size: 14px;
    width: 60px;
  }

  .content {
    font-size: 16px;
  }
}
</style>
