<template>
  <section class="change-box" ref="box">
    <div class="change-list">
      <ul>
        <li class="change-item">
          <div class="country">
            <i class="icon">
              <img src="../assets/img/bitcoin.png" width="40" alt="bitcoin">
            </i>
            <div class="name">
              <h5>BTC</h5>
              <span style="font-size: 12px; white-space: nowrap;">比特币</span>
            </div>
          </div>
          <label class="change-input">
            <span>฿</span>
            <input class="change-input-box" v-model="btcShow" @input="calCny()"/>
          </label>
        </li>
        <li class="change-item">
          <div class="country">
            <i class="flag-icon flag-icon-cn country-icon"></i>
            <div class="name">
              <h5>CNY</h5>
              <span>中国</span>
            </div>
          </div>
          <label class="change-input">
            <span>¥</span>
            <input class="change-input-box" v-model="cnyShow" @input="calBtc()"/>
          </label>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import {inject, ref} from 'vue'
import { onClickOutside } from '@vueuse/core'
export default {
  name: "ToolsBtc",
  setup(props, {emit}) {
    /**
     * 点击在比特币换算以外，关闭弹出窗口
     * */
    const box = ref(null) // 不会触发关闭的区域
    onClickOutside(box, () => {
      emit('close') // 点击在区域以外，关闭弹窗消息
    })
    /**
     * 比特币汇率
     * */
    const btc = inject('btc')
    const btcShow = ref(1)
    const cnyShow = ref(Number(btc.value))
    // 比特币换算人民币
    function calCny () {
      console.log(btcShow.value)
      console.log(btc.value)
      cnyShow.value = (btcShow.value * btc.value).toFixed(2) // 保留2位小数
    }
    // 人民币换算比特币
    function calBtc () {
      console.log(btcShow.value)
      btcShow.value = (cnyShow.value / btc.value).toFixed(7) // 保留7位小数
    }
    return {
      box, btcShow, cnyShow, calCny, calBtc
    }
  }
}
</script>

<style scoped>
.change-box{
  background: #FFFFFF;
  color: #2A3C4C;
}
.change-box h2 {
  font-size: 18px;
}

.country-icon {
  width: 48px;
  height: 36px;
  border-radius: 4px;
  overflow: hidden;
}

.change-title {
  border-radius: 2px 2px 0 0;
  padding: 10px;
  background: #eff1f5;
  display: flex;
}
.change-title span{
  display: block;
  width: 30px;
  text-align: center;
  cursor: pointer;
  font-size: 18px;
}

.change-title h2 {
  font-size: 16px;
  flex: 1;
}

.change-item {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px #eff1f5 solid;
}

.change-item:last-child {
  border-bottom: none;
}

.country {
  display: flex;
  line-height: 18px;
  min-width: 90px;
}

.country .name {
  padding-left: 5px;
}

.country .name h5 {
  font-size: 14px;
}

.change-list {
  padding: 0 10px;
}

.change-input {
  display: flex;
  font-size: 24px;
  margin-left: 40px;
}

.change-input span{
  color: #9AA5B0;
}

.change-input-box {
  border: none;
  border-bottom: 1px #9AA5B0 solid;
  width: 120px;
  font-size: 24px;
  outline: none;
  margin-left: 5px;
  font-weight: 500;
}

.change-input-box:focus {
  border-bottom: 1px #FF9900 solid;
}
.icon{
  display: flex;
  width: 48px;
  align-items: center;
}
</style>
