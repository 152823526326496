<template>
  <aside class="status-bar">
    <section class="status-bar-init">
      <span class="title">
        实时汇率  <i class="none-768">(更新时间: {{ money.updateTime }})</i>
      </span>
      <div class="exchanges">
        <ul class="money">
          <li>1美元 = {{ money.dollarExchangeRate }}元</li>
          <li class="none-576">1英镑 = {{ money.poundExchangeRate }}元</li>
          <li class="none-768">1欧元 = {{ money.euroExchangeRate }}元</li>
          <li class="none-1000">1日元 = {{ money.yenExchangeRate }}元</li>
          <li class="none-1100">1加元 = {{ money.cadExchangeRate }}元</li>
          <li class="none-1100">1港币 = {{ money.hkdExchangeRate }}</li>
          <li><span class="btc">1BTC = {{ btc }}元</span></li>
        </ul>
      </div>
    </section>
  </aside>
</template>

<script>
import {inject, watch} from 'vue'

export default {
  name: "TheStatusBar",
  setup() {
    const money = inject('money')
    const btc = inject('btc')
    const moneyFinish = inject('moneyFinish')
    const btcFinish = inject('btcFinish')

    watch (moneyFinish, (newVal)=>{
      if (newVal) {
        console.log('*********汇率更新完成*********')
      } else {
        return false
      }
    })

    watch(btcFinish, (newVal) => {
      if (newVal) {
        console.log('*********BTC更新完成*********')
      } else {
        return false
      }
    })
    return {
      money, btc
    }
  }
}
</script>

<style scoped>
.status-bar {
  height: 36px;
  line-height: 36px;
  background-color: #2A3C4C;
  color: #AAB1B7;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.status-bar-init {
  max-width: 1200px;
  display: flex;
}

.title{
  overflow: hidden;
}
.title i {
  padding-right: 20px;
}

.exchanges{
  overflow: hidden;
  white-space: nowrap;
}

ul.money {
  display: flex;
  padding: 10px 0;
  line-height: 16px;
}

ul.money li {
  padding: 0 10px;
  border-right: 1px #53616D solid;
}

ul.money li:last-child {
  border: none;
}

.btc {
  color: #42B983;
}
.zz{
  color: #FFFFFF;
}
</style>
