<template>
  <header class="header">
    <aside class="logo">
      <img src="../assets/img/logo-tiny.png" height="48" alt="logo">
    </aside>
    <div class="language">
      <div class="language-select" @click="languageShow = true">
        <span>{{ languageName }}</span>
        <i class="iconfont icon-down">&#xe665;</i>
      </div>
      <div class="language-list" v-if="languageShow">
        <ul>
          <li @click="changeLanguage('cn')">简体中文</li>
          <li @click="changeLanguage('en')">English</li>
        </ul>
      </div>
    </div>
    <ul class="tools">
      <li>
        <a href="#" @click="changeShow = true">汇率计算</a>
        <aside class="tools-change" v-if="changeShow">
          <header class="change-title">
            <h2>汇率转换</h2>
            <span class="close" @click="changeShow = false">X</span>
          </header>
          <tools-exchange-conversion
              @close="changeShow = false"
          ></tools-exchange-conversion>
        </aside>
      </li>
      <!--比特币转换-->
      <li>
        <a href="#" @click="btcShow = true">比特币换算</a>
        <aside class="tools-change" v-if="btcShow">
          <header class="change-title">
            <h2>比特币换算</h2>
            <span class="close" @click="btcShow = false">X</span>
          </header>
          <tools-btc
              @close="btcShow = false"
          ></tools-btc>
        </aside>
      </li>
      <!--密码生成-->
      <li>
        <a href="#" @click="pwdShow = true">密码生成</a>
        <aside class="tools-change" v-if="pwdShow">
          <header class="change-title">
            <h2>密码生成</h2>
            <span class="close" @click="pwdShow = false">X</span>
          </header>
          <tools-password
              @close="pwdShow = false"
          ></tools-password>
        </aside>
      </li>
      <li>
        <a href="http://houniao.yalala.com/" target="_blank">指纹浏览器</a>
      </li>
      <li>
        <a href="https://www.chaojivps.com/" target="_blank">超级VPS管理器</a>
      </li>
    </ul>
    <ip-country-search></ip-country-search>
  </header>

</template>

<script>
import IpCountrySearch from "./IpCountrySearch.vue";
import ToolsExchangeConversion from "./ToolsExchangeConversion.vue"
import ToolsBtc from "@/components/ToolsBtc.vue"
import ToolsPassword from "@/components/ToolsPassword";

export default {
  name: "TheHeader",
  components: {
    ToolsPassword,
    ToolsBtc,
    ToolsExchangeConversion,
    IpCountrySearch
  },
  data() {
    return {
      languageShow: false,
      languageSelect: 'cn',
      changeShow: false,
      btcShow: false,
      pwdShow: false
    }
  },
  methods: {
    changeLanguage(cc) {
      this.languageShow = false
      switch (cc) {
        case 'cn' :
          this.languageSelect = 'cn'
          this.$emit('language-change', 'cn')
          break
        case 'en' :
          this.languageSelect = 'en'
          this.$emit('language-change', 'en')
          break
        default:
          break
      }
    }
  },
  computed : {
    languageName: function () {
      if (this.languageSelect === 'en') {
        return 'English'
      } else {
        return '简体中文'
      }
    }
  },
  watch: {
  }
}
</script>

<style scoped>
.header {
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px 0;
  display: flex;
  align-items: flex-end;
}
.language {
  padding-bottom: 3px;
  padding-left: 15px;
  color: #9AA5B0;
  position: relative;
  width: 100px;
}

.icon-down {
  font-size: 10px;
  padding-left: 5px;
}

ul.tools {
  flex: 1;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  padding-left: 20px;
  padding-bottom: 4px;
}

ul.tools li {
  padding: 0 15px;
  position: relative;
}

ul.tools li a{
  color: #FFFFFF;
}

.language-select {
  cursor: pointer;
}

.language-list {
  position: absolute;
  left: 0;
  top: 30px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 4px;
}

.language-list ul li {
  padding: 5px 10px;
  cursor: pointer;
  color: #2c3e50;
}

.language-list ul li:hover {
  background: #2c3e50;
  color: #FFFFFF;
}

.tools-change {
  position: absolute;
  left: 15px;
  top: 30px;
  z-index: 100;
  box-shadow: inset 1px 1px 0 0 hsla(0, 0%, 100%, 0.2), 0 3px 8px 0 #555a64;
  border-radius: 4px;
}

.change-title {
  border-radius: 2px 2px 0 0;
  padding: 10px;
  background: #eff1f5;
  display: flex;
  color: #2A3C4C;
}
.change-title span{
  display: block;
  width: 30px;
  text-align: center;
  cursor: pointer;
  font-size: 18px;
}

.change-title h2 {
  font-size: 16px;
  flex: 1;
}

@media only screen and ( max-width: 680px ) {
  ul.tools{
    display: none;
  }
  .language{
    flex: 1;
  }
}
@media only screen and ( max-width: 600px ) {
  .language{
    display: none;
  }
  .logo{
    flex: 1;
  }
}
@media only screen and ( max-width: 480px ) {
  .header{
    flex-wrap: wrap;
    justify-content: center;
  }
  .logo{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .search{
    margin-top: 20px;
    width: 100%;
  }
}
</style>
