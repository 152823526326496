<template>
  <div class="password" ref="box">
    <label class="pwd-show">
      <input type="text" v-model="randomPassword" class="password-input" />
    </label>
    <label class="operation">
      <button @click="copy(randomPassword)" class="copy">复制安全密码</button>
      <button @click="createPassword" class="refresh">刷新</button>
    </label>
    <div class="pwd-options">
      <div class="pwd-digits">
        位数:
        <button @click="changeDigits(8)" :class="{active: digits === 8}">8</button>
        <button @click="changeDigits(12)" :class="{active: digits === 12}">12</button>
        <button @click="changeDigits(16)" :class="{active: digits === 16}">16</button>
      </div>
      <div class="pwd-check">
        组成:
        <label>
          <input type="checkbox" v-model="make.en" value="小写">
          小写
          <input type="checkbox" v-model="make.EN" value="大写">
          大写
          <input type="checkbox" v-model="make.special" value="特殊字符">
          特殊字符
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, onMounted, watch} from "vue";
import {onClickOutside, useClipboard} from "@vueuse/core";

export default {
  name: "ToolsPassword",
  setup(props, {emit}) {
    /**
     * 点击在比特币换算以外，关闭弹出窗口
     * */
    const box = ref(null) // 不会触发关闭的区域
    onClickOutside(box, () => {
      emit('close') // 点击在区域以外，关闭弹窗消息
    })
    /**
     * 生成密码
     * */
    const base = {
      num: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
      english: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"],
      ENGLISH: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
      special: ["!", "@", "#", "$", "%", "^", "&", "*"]
    }
    const digits = ref(12) // 密码位数，默认6
    const make = ref({ // 密码组成方式
      en: true,
      EN: true,
      special: true
    })
    watch(make,()=>{
      createPassword()
    })
    const randomPassword = ref('')
    // 改变密码位数
    function changeDigits(checking) {
      digits.value = checking
      createPassword()
    }
    function createPassword() {
      randomPassword.value = ''
      let lib = base.num
      if (make.value.en) {
        lib = lib.concat(base.english)
      }
      if (make.value.EN) {
        lib = lib.concat(base.ENGLISH)
      }
      if (make.value.special) {
        lib = lib.concat(base.special)
      }
      for (let i = 0; i < digits.value; i++) {
        let min = Math.ceil(0)
        let max = Math.floor(lib.length - 1)
        let randomSub = Math.floor(Math.random() * ( max - min + 1))
        randomPassword.value += lib[randomSub]
      }
    }

    onMounted(()=>{
      createPassword()
    })
    return {
      box,
      ...useClipboard(),
      changeDigits,
      digits,
      make,
      createPassword,
      randomPassword
    }
  }
}
</script>

<style scoped>
.password {
  background: #FFFFFF;
  color: #2A3C4C;
}
.password-input {
  background: #ffffff;
  height: 48px;
  line-height: 48px;
  box-shadow: inset 0 0 0 2px #42b983;
  border-radius: 4px;
  text-align: center;
  font-size: 24px;
  border: none;
  width: 300px;
}
.pwd-show{
  display: flex;
  padding: 10px 10px 0 10px;
}
.operation{
  display: flex;
  height: 40px;
  margin-top: 6px;
  padding: 0 10px;
}
.copy{
  flex: 1;
  background: #42B983;
  color: #FFFFFF;
  box-shadow: none;
  border: none;
  border-radius: 4px;
}
.refresh{
  width: 40px;
  border: none;
  margin-left: 5px;
  border-radius: 4px;
}
.pwd-options{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  background: #F8F9FA;
  padding: 5px 10px;
}
.pwd-digits button{
  border: none;
  color: #2A3C4C;
  height: 20px;
  width: 20px;
  text-align: center;
  background: #E6E6E6;
  margin-right: 4px;
  border-radius: 4px;
}
.pwd-digits .active{
  background: #42B983;
  color: #FFFFFF;
}
.pwd-digits {
  flex: 1;
  font-size: 12px;
  font-weight: normal;
}
.pwd-check {
  font-size: 12px;
  font-weight: normal;
}
</style>
