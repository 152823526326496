<template>
  <footer class="footer">
    <div class="footer-init">
      <div class="footer-left">
        <img src="../assets/img/Yalala.com.png" width="121" alt="yalala">
        <p class="intro">
          YALALA适合于检查Proxy和Socks服务器、代理IP地址的DNS、操作系统版本、浏览器指纹、WebRTC泄露、位置、语言等伪装度查询。该服务的核心功能和主要优势是通过Java、Flash、WebRTC进行的互动检查，能够发现系统的真实设置和弱点，避免它方资源了解到您不愿意自己分享的信息。
        </p>
        <p class="copyright">
          Copyright © yalala.com 2008-2023
        </p>
      </div>
      <div class="footer-right">
        <h2>友情链接</h2>
        <ul>
          <li>
            <a href="https://www.mbbrowser.com/" target="_blank">候鸟指纹浏览器</a>
          </li>
          <li>
            <a href="https://www.chaojivps.com/" target="_blank">超级VPS管理器</a>
          </li>
        </ul>
      </div>
    </div>

  </footer>
</template>

<script>
export default {
  name: "TheFooter"
}
</script>

<style scoped>
.footer{
  background: #06060b;
  padding: 30px 0 200px 0;
  color: #60606d;
}
.footer .footer-init{
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  padding: 0 20px;
}

.footer .footer-init .intro{
  font-size: 12px;
  margin-top: 20px;
}
.footer-init .footer-left {
  width: 60%;
}
.footer-init .footer-right{
  width: 40%;
}
.footer-right{
  padding-left: 40px;
}
.footer-right h2{
  color: #FFFFFF;
  font-size: 14px;
  margin-bottom: 20px;
}
.footer-right ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer-right a{
  color: #60606d;
  margin-top: 10px;
  margin-right: 10px;
}
@media only screen and (max-width: 1024px) {
  .footer {
    padding-bottom: 100px;
  }
}
</style>
